import gql from 'graphql-tag';

export default gql`
query getUserAndRolesByEmail($email: String!, $slugPath: String, $userId: Int) {
  users(where: {email: {_eq: $email}}) {
    user_id
    firebase_id
    full_name
    last_group_id
    home_group_id
    email
    email_confirmed
    deactivated
    language_id
    language {
      handle
    }
    sys_admin
    users_roles_groups(order_by: {group_id: asc, role_id: desc}) {
      group_id
      role_id
      user_id
      group {
        group_id
        name
        root_folder_id
        slug
        domain
      }
    }
    users_roles_teams {
      team_id
      role_id
      team {
        teams_items {
          folder_id
          folder {
            item_path
            group_id
            root
          }
          project_id
          project {
            item_path
            group_id
          }
          page_id
          page {
            item_path
            group_id
          }
        }
      }
    }
  }
  
  item_lookup(where: {slug_path: {_eq: $slugPath}}) {
    domain
    item_path
    slug_path
    group_id
    folder { name }
    project { name }
    page {
      name
    }
    group {
      name
      group_id
    }
  }
  
  groups {
    group_id
    name
    settings
    created_at
  }
  
  permissions_lookup(where: {user_id: {_eq: $userId}}) {
    user_id
    group_id
    team_id
    role_id
    role_handle
    role_level
    item_path
  }
  
  roles {
    name
    role_id
    level
    handle
    roles_privileges(where: {value: {_eq: true}}) {
      value
      privilege {
        name
      }
    }
  }
  
  languages(where: {handle: {_eq: "en"}}) {
    language_id
  }
}
`;
