import gql from 'graphql-tag';

export default gql`
mutation updateUserLastGroupId(
  $uid: Int!,
  $lastGroupId: Int!,
) {
  update_users(
    where: {
      user_id: {_eq: $uid}
    }, 
    _set: {last_group_id: $lastGroupId}
  ) {
    affected_rows
  }
}
`;
