import GetGroupObjectByGroupId from '@/graphQlQueries/queries/getGroupObjectByGroupId';
import UpdateUserLastGroupId from '@/graphQlQueries/mutations/updateUserLastGroupId';
import Auth from '@aws-amplify/auth';
import GetUserObjectByEmail from '@/graphQlQueries/queries/getUserObjectByEmail';

const userDataAndAccess = {
  data() {
    return {
      usedInHeader: false,
      groupId: null,
      disableAccount: false,
      slugPath: null,
      isGroupUser: null,
      teamIdsOfUser: null,
      userId: null,
      userEmail: null,
      englishLanguageId: null,
      allAvailablePrivileges: null,
      currentGroupPrivileges: null,
      languageOfBrowser: null,
      userObject: {
        userId: null,
        userGroupRoleIds: null,
        isGroupUser: null,
        groupsOfUser: null,
        groupIdsOfUser: null,
        teamIdsOfUser: null,
        teamItemsOfUser: null,
        isSysAdmin: null,
        language: null,
        name: null,
      },
      userObjectQuery: null,
      authenticatedUser: null,
    };
  },
  async created() {
    this.languageOfBrowser = navigator.language || navigator.userLanguage;
    try {
      const currentSession = await Auth.currentSession();
      const idTokenPayload = JSON.parse(currentSession.getIdToken().payload['https://hasura.io/jwt/claims']);
      this.userId = (idTokenPayload['x-hasura-user-id'])
        ? Math.floor(idTokenPayload['x-hasura-user-id'])
        : null;
      // shortcut for the sysadmin
      if (idTokenPayload['x-hasura-allowed-roles'].includes('admin')) {
        this.$currentUserRole = 'admin';
      }
      this.slugPath = this.getTheSlugPathOfTheItem();
      this.userEmail = currentSession.getIdToken().payload.email;
    } catch (error) {
      console.error(error);
      this.$router.push('/webmag-logout').catch((err) => {
        throw new Error(`Routing Error occurred! ${err}.`);
      });
    }
  },
  apollo: {
    userObjectQuery: {
      query: GetUserObjectByEmail,
      variables() {
        return {
          userId: this.userId,
          email: this.userEmail,
          slugPath: this.slugPath,
        };
      },
      async update(data) {
        // if no user object is found
        if (!data.users.length) {
          this.$router.push({
            path: '/oops-something-went-wrong',
            query: { error: 'accountSetupProblem' },
          }).catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        }
        // don't allow access to the app if a user is deactivated
        if (data.users.length && data.users[0].deactivated) {
          this.$router.push('/webmag-deactivated').catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        } else if (data.users.length && !data.users[0].email_confirmed) {
          // don't allow access to the app if a user email is not confirmed
          this.$router.push({
            path: '/oops-something-went-wrong',
            query: { error: 'emailNotConfirmed' },
          }).catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        } else {
          // everything went good so proceed
          const {
            users: [user],
            groups,
            item_lookup,
            permissions_lookup,
            roles,
            languages,
          } = data;
          const groupId = this.getGroupIdOfUser(user, groups, permissions_lookup);
          this.englishLanguageId = languages[0].language_id;
          if (groupId === -1) {
            // user has no group and no team
            this.$router.push({
              path: '/no-group-or-team-dashboard',
            });
          } else if (groupId === false) {
            // user belongs to a team with no items and has no group
            this.$router.push({
              path: '/no-items-dashboard',
            });
          } else {
            if (groupId !== user.last_group_id && !this.usedInHeader) {
              await this.$apollo.mutate({
                mutation: UpdateUserLastGroupId,
                variables: {
                  uid: user.user_id,
                  lastGroupId: groupId,
                },
              });
            }
            // check if the group is deactivated and redirect to group deactivated page
            if (this.usedInHeader) {
              const groupItem = groups.find((group) => group.group_id === groupId);
              if (
                groupItem.settings?.isTestAccount === true
              ) {
                const createdAt = new Date(groupItem.created_at);
                const currentDate = new Date();
                const diffInMilliseconds = currentDate - createdAt;
                const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);
                if (diffInDays > 14) {
                  if (this.$route.name !== 'FreeAccountExpired') {
                    // here we have to change the redirect to the group deactivated page
                    await this.$router.push({
                      path: '/free-account-expired',
                    });
                  }
                }
              }
            }
            // now design the user role!!!
            // get the group role first
            // there can only be one role per user on each group!!!
            if (!this.usedInHeader) {
              const allRolesForGroup = [];
              const userRoleForGroup = this.getUserRolesForGroup(
                user.users_roles_groups, roles, groupId,
              );
              if (userRoleForGroup) {
                allRolesForGroup.push(userRoleForGroup);
              }
              const itemPathObject = item_lookup.find(
                (item) => item.group_id === groupId && item.slug_path === this.slugPath,
              );
              const itemPath = (itemPathObject) ? itemPathObject.item_path : null;
              if (user.sys_admin) {
                // if the user is sysadmin he has access to all the data
                this.$currentUserRole = 'admin';
              } else if (itemPath) {
                // query the permissions_lookup
                const permissionObject = permissions_lookup.find(
                  (item) => itemPath.indexOf(item.item_path) !== -1,
                );
                allRolesForGroup.push({
                  roleId: permissionObject.role_id,
                  handle: permissionObject.role_handle,
                  level: permissionObject.role_level,
                });
                // sort the array after the level
                allRolesForGroup.sort((a, b) => a.level - b.level);
                this.$currentUserRole = allRolesForGroup[0].handle;
              } else if (this.slugPath !== '/') {
                if (this.slugPath === '') {
                  if (!allRolesForGroup.length) {
                    // get the last_group_id of the user to decide in which group the user is
                    if (!user.last_group_id) {
                      this.$currentUserRole = 'user';
                      // it's not possible to decide the group so use 'user' as the role
                    } else {
                      const permissionObject = permissions_lookup.find(
                        (item) => item.group_id === user.last_group_id,
                      );
                      if (permissionObject) {
                        this.$currentUserRole = permissionObject.role_handle;
                      } else {
                        this.$currentUserRole = 'user';
                      }
                    }
                  } else {
                    this.$currentUserRole = allRolesForGroup[0].handle;
                  }
                } else {
                  this.$currentUserRole = 'user';
                }
              } else {
                // the user is on the team dashboard
                // we set the role to user
                this.$currentUserRole = 'user';
              }
            }
            // TODO: this is from the old version an has to be reworked but it works
            this.allAvailablePrivileges = data.roles;
            this.teamIdsOfUser = this.getAllTeamIdsOfUser(user);
            this.groupsOfUser = this.getAllGroupsOfUser(user);
            this.isGroupUser = this.checkForGroupUser(user.users_roles_groups, groupId);
            this.userObject = this.setupUserObject(user, groupId);
            this.groupId = groupId;
            if (this.userObject.language === 'de') {
              this.$root.$i18n.locale = 'de-DE';
            } else {
              this.$root.$i18n.locale = 'en-US';
            }
            if (!this.usedInHeader) {
              // we set the gist tracking if the mixing is not in the header component
              this.addGistToApplication();
            }
          }
        }
      },
      error(errorMsg) {
        const debugData = `${this.userEmail}, ${this.userId}, ${this.slugPath}`;
        if (errorMsg.message.includes('Your requested role is not in allowed roles')) {
          this.$router.push({
            path: '/not-in-allowed-roles',
            query: {
              error: 'accountSetupProblem',
              debugData,
              errorData: JSON.stringify(errorMsg),
            },
          }).catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        } else {
          this.$router.push({
            path: '/oops-something-went-wrong',
            query: {
              error: 'accountSetupProblem',
              debugData,
              errorData: JSON.stringify(errorMsg),
            },
          }).catch((err) => {
            throw new Error(`Routing Error occurred! ${err}.`);
          });
        }
      },
      skip() {
        return !this.userEmail || !this.userId || this.slugPath === null;
      },
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    getTheSlugPathOfTheItem() {
      if (this.$route.params.parametersOfUrl) {
        return `/${this.$route.params.parametersOfUrl}`;
      }
      return (this.$route.path === '/') ? '/' : '';
    },
    setupUserObject(user, groupId) {
      const userGroupRoleIds = this.getUserGroupRoleIds(user.users_roles_groups, groupId);
      const groupsOfUser = this.getAllGroupsOfUser(user);
      const teamIdsOfUser = this.getAllTeamIdsOfUser(user);
      this.currentGroupPrivileges = this.getAllPrivilegesByRoles(userGroupRoleIds);
      const userLangBrowserString = navigator.language || navigator.userLanguage;
      const userLang = userLangBrowserString.substring(0, userLangBrowserString.indexOf('-'));
      const language = (!user.language) ? userLang : user.language.handle;
      return {
        userId: user.user_id,
        userGroupRoleIds,
        isGroupUser: this.isGroupUser,
        groupsOfUser,
        groupIdsOfUser: this.groupIdsOfUser,
        teamIdsOfUser,
        teamItemsOfUser: user.users_roles_teams,
        isSysAdmin: user.sys_admin,
        language,
        email: user.email,
        name: user.full_name,
      };
    },
    getUserRolesForGroup(userRoles, roles, groupId) {
      const groupRoleObject = userRoles.find(
        (item) => item.group_id === groupId,
      );
      if (groupRoleObject) {
        // get the role with the role id
        const roleObject = roles.find((role) => role.role_id === groupRoleObject.role_id);
        return {
          roleId: roleObject.role_id,
          handle: roleObject.handle,
          level: roleObject.level,
        };
      }
      return null;
    },
    getUserGroupRoleIds(groups, groupId) {
      const userRolesForCurrentGroup = [];
      // get all roles of the current group
      groups.forEach((roleGroupItem) => {
        if (
          roleGroupItem.group_id === groupId
          && !userRolesForCurrentGroup.includes(roleGroupItem.role_id)
        ) {
          userRolesForCurrentGroup.push(roleGroupItem.role_id);
        }
      });
      return userRolesForCurrentGroup;
    },
    getAllPrivilegesByRoles(roleIds) {
      let privileges = [];
      roleIds.forEach((roleId) => {
        // get the entry with the roleId
        const {
          roles_privileges: privilegesOfRole,
        } = this.allAvailablePrivileges.find((priv) => priv.role_id === roleId);
        const allPrivilegesOfRolesByName = privilegesOfRole.map((item) => item.privilege.name);
        privileges = [...new Set([
          ...privileges,
          ...allPrivilegesOfRolesByName,
        ])];
      });
      return privileges;
    },
    checkForGroupUser(userGroups, groupId) {
      const groupUser = userGroups.find((userGroup) => userGroup.group_id === groupId);
      return !!(groupUser);
    },
    getAllGroupsOfUser(user) {
      const groups = [];
      this.groupIdsOfUser = [];
      user.users_roles_groups.forEach((groupObject) => {
        if (!this.groupIdsOfUser.includes(groupObject.group_id)) {
          this.groupIdsOfUser.push(groupObject.group_id);
          groups.push(groupObject.group);
        }
      });
      return groups;
    },
    getAllTeamIdsOfUser(user) {
      const teamIds = [];
      user.users_roles_teams.forEach((teamObject) => {
        if (!teamIds.includes(teamObject.team_id)) {
          teamIds.push(teamObject.team_id);
        }
      });
      return teamIds;
    },
    getGroupIdOfUser(user, groups, permissionsLookup) {
      /*
       * Check the following order if there is an entry
       * 1) get the last_group_id
       * 2) get the home_group_id
       * 3) get the first entry from the users_roles_groups
       * 4) get the first entry from the users_roles_teams
       */
      if (
        user.last_group_id
        && (groups.find((group) => group.group_id === user.last_group_id) || user.sys_admin)
      ) {
        return user.last_group_id;
      }
      if (
        user.home_group_id
        && (groups.find((group) => group.group_id === user.home_group_id) || user.sys_admin)
      ) {
        return user.home_group_id;
      }

      if (user.users_roles_groups.length && user.users_roles_groups[0].group_id) {
        return user.users_roles_groups[0].group_id;
      }

      // check the permissions lookup and take the first element with a group id and team id
      const permissionsLookupObjectForGroupId = permissionsLookup.find(
        (lookupItem) => lookupItem.group_id !== null && lookupItem.team_id !== null,
      );

      if (permissionsLookupObjectForGroupId) {
        return permissionsLookupObjectForGroupId.group_id;
      }
      // the last check we can do is to go through all teams
      // and get the group out of one of the items
      return this.getGroupIdFromTheUserTeams(user);
    },
    getGroupIdFromTheUserTeams(user) {
      let groupId = false;
      if (user.users_roles_teams.length === 0) {
        return -1;
      }
      user.users_roles_teams.forEach((userRoleTeam) => {
        userRoleTeam.team.teams_items.forEach((teamItem) => {
          if (teamItem.folder) {
            groupId = teamItem.folder.group_id;
          }
          if (teamItem.project) {
            groupId = teamItem.project.group_id;
          }
          if (teamItem.page) {
            groupId = teamItem.page.group_id;
          }
        });
      });
      return groupId;
    },
    addGistToApplication() {
      if (typeof window.gist !== 'undefined' && this.userObject) {
        this.$apollo.query({
          query: GetGroupObjectByGroupId,
          variables: {
            groupId: this.groupId,
          },
        }).then((result) => {
          if (result.data.groups.length) {
            const { data: { groups: [group] } } = result;
            const groupName = group.name;
            window.gist.identify(this.userObject.email, {
              email: this.userObject.email,
              last_group: groupName,
            });
          } else {
            console.warn('no group found', result);
          }
        });
      }
    },
  },
};

export default userDataAndAccess;
