import gql from 'graphql-tag';

export default gql`
query getGroupObjectByGroupId($groupId: Int!) {
  groups(where: {group_id: {_eq: $groupId}}) {
    slug
    domain
    name
  }
}
`;
